import { useMediaQuery } from '../../hooks/use-media-query';
const smallContainer = {
  width: 960,
  aspectRatio: '1/1',
  visibleViewport: 'small'
};
const mediumContainer = {
  width: 960,
  aspectRatio: '16/9',
  visibleViewport: 'medium'
};
const largeContainer = {
  width: 1920,
  aspectRatio: '16/9',
  visibleViewport: 'large'
};
const xLargeUpContainer = {
  width: 1920,
  aspectRatio: '19/8',
  visibleViewport: 'xLargeUp'
};

// This is the fallback for SSR where no MediaQuery matches.
//   -> All hero stages are rendered on server side
const allContainers = [smallContainer, mediumContainer, largeContainer, xLargeUpContainer];
export const useViewportContainers = () => useMediaQuery([['small', [smallContainer]], ['medium', [mediumContainer]], ['large', [largeContainer]], ['xLargeUp', [xLargeUpContainer]]], allContainers);