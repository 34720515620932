/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { usePrefersReducedMotion } from '../../hooks/use-reduced-motion';
import { useDeviceState } from '../../providers/DeviceProvider/DeviceContext';
const wheelThreshold = 2;
const autoSlideInterval = 7000;
export const useSlider = (numberOfSlides, slides) => {
  const lastWheelXDistanceRef = useRef(0);
  const blockWheelSlideRef = useRef(false);
  const [progress, setProgress] = useState(0);
  const [shiftInProgress, setShiftInProgress] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const posDragStartRef = useRef({
    x: 0,
    y: 0
  });
  const isTouchRef = useRef(false);
  const touchDirectionRef = useRef('none');
  const [isHovering, setIsHovering] = useState(false);
  const [stopAutoslide, setStopAutoslide] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();
  const {
    isTouchDevice
  } = useDeviceState();
  const autoSlideActive = !isHovering && !stopAutoslide && !prefersReducedMotion && !isTouchDevice;
  useEffect(() => {
    if (autoSlideActive) {
      const interval = setInterval(() => {
        shiftSlide(1, 'autoslide');
      }, autoSlideInterval);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSlideActive]);
  const dragStart = e => {
    isTouchRef.current = true;
    slides?.current?.classList.remove('shifting');
    if (e.type === 'touchstart') {
      posDragStartRef.current = {
        x: e.touches[0].clientX,
        y: e.touches[0].clientY
      };
    } else {
      posDragStartRef.current = {
        x: e.clientX,
        y: e.clientY
      };
    }
  };
  const dragEnd = () => {
    if (isTouchRef.current) {
      shiftSlide(Math.round(progress - currentIndex));
    }
    isTouchRef.current = false;
    touchDirectionRef.current = 'none';
  };
  const shiftSlide = (dir, source) => {
    slides?.current?.classList.add('shifting');
    const slidesElement = slides.current;
    if (!shiftInProgress && slidesElement) {
      if (dir === 0) {
        setProgress(currentIndex);
      }
      setCurrentIndex(index => (index + dir + numberOfSlides) % numberOfSlides);
      // For wheel scroll, the interaction should be blocked until the transition ended
      if (source === 'wheel') {
        setShiftInProgress(true);
      }
      setStopAutoslide(source !== 'autoslide');
    }
  };
  const dragAction = useCallback(e => {
    const event = e || window.event;
    let xDrag;
    if (isTouchRef.current) {
      if (event.type === 'touchmove') {
        if (touchDirectionRef.current === 'y' || shiftInProgress) {
          return;
        }
        xDrag = posDragStartRef.current.x - event.touches[0].clientX;
        const yDrag = posDragStartRef.current.y - event.touches[0].clientY;
        const yDragAbs = Math.abs(yDrag);
        const xDragAbs = Math.abs(xDrag);
        if (xDragAbs >= yDragAbs || touchDirectionRef.current === 'x') {
          touchDirectionRef.current = 'x';
          event.preventDefault();
        } else {
          touchDirectionRef.current = 'y';
          return;
        }
      } else {
        xDrag = posDragStartRef.current.x - event.clientX;
      }
      if (slides.current) {
        const moveThreshold = window ? window.innerWidth / 2 : 0;
        const slideProgress = xDrag / moveThreshold;
        // Prevent sliding more than one slide
        const clampedSlideProgress = Math.max(-1, Math.min(1, slideProgress));
        setProgress(currentIndex + clampedSlideProgress);
      }
    }
  }, [currentIndex, shiftInProgress, slides]);
  const transitionEnd = () => {
    setShiftInProgress(false);
  };
  const wheelSlide = ({
    deltaX,
    deltaY
  }) => {
    const isXMotion = Math.abs(deltaX) >= Math.abs(deltaY);
    if (Math.abs(deltaX) > wheelThreshold && isXMotion) {
      const isXIncrease = Math.abs(deltaX) > lastWheelXDistanceRef.current;
      if (!blockWheelSlideRef.current && isXIncrease) {
        shiftSlide(deltaX < 0 ? -1 : 1, 'wheel');
        blockWheelSlideRef.current = true;
      }
      if (!isXIncrease) {
        wheelSlideResetBlockDebounced();
      }
      lastWheelXDistanceRef.current = Math.abs(deltaX);
    }
  };
  const resetLastWheelSlide = () => {
    blockWheelSlideRef.current = false;
  };
  const wheelSlideResetBlockDebounced = useDebouncedCallback(resetLastWheelSlide, 300, {
    maxWait: 500,
    trailing: true
  });
  useEffect(() => {
    setProgress(currentIndex);
  }, [currentIndex]);
  useEffect(() => {
    const slidesNode = slides.current;
    slidesNode?.addEventListener('touchmove', dragAction);
    return () => {
      slidesNode?.removeEventListener('touchmove', dragAction);
    };
  }, [dragAction, slides]);
  const onMouseEnter = () => setIsHovering(true);
  const onMouseLeave = () => setIsHovering(false);
  useEffect(() => {
    const slidesNode = slides.current;
    slidesNode?.addEventListener('mouseenter', onMouseEnter);
    slidesNode?.addEventListener('mouseleave', onMouseLeave);
    return () => {
      slidesNode?.removeEventListener('mouseenter', onMouseEnter);
      slidesNode?.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [dragAction, slides]);
  return {
    dragStart,
    dragEnd,
    dragAction,
    transitionEnd,
    wheelSlideDebounced: wheelSlide,
    shiftSlide,
    currentIndex,
    progress
  };
};