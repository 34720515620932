import { Link } from "@remix-run/react";
import { styled, css } from 'styled-components';
import { Colors } from '../../../../../play-core/styles/Colors';
import { Fonts } from '../../../../../play-core/styles/Fonts';
import { getMultilineTruncation } from '../../../../../play-core/styles/style-helper';
import { Viewports } from '../../../../../play-core/styles/Viewports';
export const ContainerStyle = css(["display:flex;flex-direction:column;align-items:flex-start;height:100%;padding:24px 24px 28px;border-radius:4px;overflow:hidden;position:relative;", "{padding:16px 16px 28px;}", "{padding:8px 8px 20px;}"], Viewports.for('medium'), Viewports.for('small'));
export const StyledLink = styled(Link).withConfig({
  shouldForwardProp: () => true
}).withConfig({
  displayName: "LivestreamTeaserstyles__StyledLink",
  componentId: "sc-z9dv6t-0"
})(["", ";"], ContainerStyle);
export const Title = styled.div.withConfig({
  displayName: "LivestreamTeaserstyles__Title",
  componentId: "sc-z9dv6t-1"
})(["margin-top:24px;min-height:24px;", ";color:", ";", ";", "{margin-top:16px;", ";min-height:20px;", ";}"], Fonts.regular(18), Colors.WHITE, getMultilineTruncation(3, 24), Viewports.for('small'), Fonts.regular(16), getMultilineTruncation(3, 20));
export const BackgroundImage = styled.div.withConfig({
  displayName: "LivestreamTeaserstyles__BackgroundImage",
  componentId: "sc-z9dv6t-2"
})(["top:0;left:0;bottom:0;right:0;overflow:hidden;height:100%;position:absolute;transition:opacity 200ms ease-in-out;z-index:-1;background-color:", ";"], Colors.GREY_3a3a3a);
export const ChannelImage = styled.img.withConfig({
  displayName: "LivestreamTeaserstyles__ChannelImage",
  componentId: "sc-z9dv6t-3"
})(["height:30px;", "{height:40px;}"], Viewports.for('largeUp'));