import { useEffect, useState } from 'react';
import { isBrowser } from '../../../play-core/utils/ssr-helper';
const query = '(prefers-reduced-motion: no-preference)';
export const usePrefersReducedMotion = () => {
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(() => isBrowser && !window.matchMedia(query).matches);
  const listener = event => {
    setPrefersReducedMotion(!event.matches);
  };
  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    mediaQueryList.addEventListener('change', listener);
    return () => {
      mediaQueryList.removeEventListener('change', listener);
    };
  }, []);
  return prefersReducedMotion;
};