import { useEffect, useState } from 'react';
import { shuffleArray } from '../../../play-core/utils/random';
export const useItems = (plainElements, pickRandomElement, predefinedRandom) => {
  const shuffledElements = predefinedRandom || shuffleArray(plainElements);
  const [elements, setElements] = useState(pickRandomElement ? shuffledElements : plainElements);
  useEffect(() => {
    if (pickRandomElement && elements.length < 1 && plainElements.length > 0) {
      setElements(pickRandomElement ? shuffledElements : plainElements);
    } else if (elements.length !== plainElements.length) {
      setElements(plainElements);
    }
  }, [plainElements, elements.length, pickRandomElement, shuffledElements]);
  return elements;
};