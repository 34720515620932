import { styled, css } from 'styled-components';
import { Colors, hexToRGB } from '../../../../play-core/styles/Colors';
import { Device } from '../../../../play-core/styles/Device';
import { Fonts } from '../../../../play-core/styles/Fonts';
import { pulsate } from '../../../../play-core/styles/pulsate';
import { getMultilineTruncation } from '../../../../play-core/styles/style-helper';
import { Orientations, Viewports } from '../../../../play-core/styles/Viewports';
import { aspectRatioMap } from '../Image/PlayImage';
import { buttonSize, horizontalMargin } from '../Slider/Slider.styles';
export const Title = styled.h2.withConfig({
  displayName: "HeroStageOverlaystyles__Title",
  componentId: "sc-1r9903o-0"
})(["margin:0;", ";line-height:30px;", ";", "{", ";line-height:40px;", ";}"], Fonts.bold(24), getMultilineTruncation(3, 30), Viewports.for('largeUp'), Fonts.bold(34), getMultilineTruncation(3, 40));
const navigationButtonOffset = buttonSize + horizontalMargin;

// % of the background height should be covered by the section

const sectionPercentage = {
  default: {
    default: 1.1,
    mediaQuery: [['landscape', 0.6]]
  },
  mediaQuery: [['medium', {
    default: 0.9,
    mediaQuery: []
  }], ['largeUp', {
    default: 0.75,
    mediaQuery: []
  }]]
};
const heightByOrientation = (aspectRatio, orientationMediaQuery) => css(["height:calc((100vw / ", ") * ", ");", ";"], aspectRatioMap[aspectRatio], orientationMediaQuery.default, orientationMediaQuery.mediaQuery.map(([orientation, percentage]) => css(["", "{height:calc((100vw / ", ") * ", ");}"], Orientations.for(orientation), aspectRatioMap[aspectRatio], percentage)));
const getHeight = aspectRatio => css(["", " ", ";"], heightByOrientation(aspectRatio, sectionPercentage.default), sectionPercentage.mediaQuery.map(([viewport, orientationMediaQuery]) => css(["", "{", "}"], Viewports.for(viewport), heightByOrientation(aspectRatio, orientationMediaQuery))));
export const Container = styled.div.withConfig({
  displayName: "HeroStageOverlaystyles__Container",
  componentId: "sc-1r9903o-1"
})(["display:flex;flex-direction:column;justify-content:flex-end;align-items:center;overflow:hidden;", ";", "{padding-bottom:50px;}padding-bottom:100px;", "{padding-left:", "px;padding-right:", "px;}", "{padding-left:0;padding-right:0;}"], ({
  aspectRatio
}) => getHeight(aspectRatio), Viewports.for('mediumDown'), Viewports.for('mediumUp'), navigationButtonOffset, navigationButtonOffset, Device.touch);
export const HeroStagePlaceholder = styled.div.withConfig({
  displayName: "HeroStageOverlaystyles__HeroStagePlaceholder",
  componentId: "sc-1r9903o-2"
})(["", ";position:relative;background-color:", ";background-image:linear-gradient(180deg,", " 50%,", " 100%);width:100%;animation:", " 1s ease-in-out infinite alternate;"], ({
  aspectRatio
}) => getHeight(aspectRatio), Colors.GREY_2a2a2a, hexToRGB(Colors.GREY_1a1a1a, 0), hexToRGB(Colors.GREY_1a1a1a, 1), pulsate);
export const FixMeta = styled.div.withConfig({
  displayName: "HeroStageOverlaystyles__FixMeta",
  componentId: "sc-1r9903o-3"
})(["color:", ";display:flex;flex-direction:column;align-items:flex-start;text-align:left;max-width:min(465px,75vw);gap:12px;", "{gap:16px;}"], Colors.WHITE, Viewports.for('mediumUp'));
export const MetaInformation = styled.div.withConfig({
  displayName: "HeroStageOverlaystyles__MetaInformation",
  componentId: "sc-1r9903o-4"
})(["display:flex;white-space:nowrap;flex-wrap:wrap;align-items:center;color:", ";line-height:initial;", ";"], Colors.WHITE, Fonts.medium(16));
export const Description = styled.span.withConfig({
  displayName: "HeroStageOverlaystyles__Description",
  componentId: "sc-1r9903o-5"
})(["", ";line-height:28px;", ";", "{", ";}"], Fonts.light(18), getMultilineTruncation(3, 28), Viewports.for('small'), getMultilineTruncation(2, 28));
export const TextContainer = styled.div.withConfig({
  displayName: "HeroStageOverlaystyles__TextContainer",
  componentId: "sc-1r9903o-6"
})(["display:flex;flex-direction:column;gap:8px;"]);
export const getAnimatedStyle = ({
  index,
  sliderProgress,
  numberOfSlides,
  transitionProgress
}) => {
  const isDragInProgress = transitionProgress % 1 !== 0;
  // Enter from right (positive translateX) and then exit to left (negative translateX)
  const lastIndex = numberOfSlides - 1;
  let isNegative = sliderProgress > index;
  if (index === lastIndex) {
    isNegative = isNegative || sliderProgress <= 0;
  } else if (index === 0) {
    isNegative = isNegative && sliderProgress < lastIndex;
  }
  const translate = (1 - transitionProgress) * 25 * (isNegative ? -1 : 1);
  return {
    transform: `translateX(${translate}vw)`,
    transition:
    // Do not animate when drag is in progress
    isDragInProgress ? 'none' : 'all 0.3s ease-in-out',
    willChange: 'transform'
  };
};