import { styled } from 'styled-components';
import { Colors } from '../../../../play-core/styles/Colors';
import { Fonts } from '../../../../play-core/styles/Fonts';
import { ellipsis, getMultilineTruncation } from '../../../../play-core/styles/style-helper';
import { Viewports, xLargeRange } from '../../../../play-core/styles/Viewports';
export const Layout = styled.section.withConfig({
  displayName: "TeaserElementstyles__Layout",
  componentId: "sc-xdp3z7-0"
})(["display:grid;grid-template-columns:100%;background-color:", ";border-radius:4px;overflow:hidden;grid-template-rows:min-content;&:hover{background-color:", ";}", "{grid-template-columns:47% auto;}", "{grid-template-columns:40% auto;}"], Colors.GREY_2a2a2a, Colors.GREY_3a3a3a, Viewports.for('large'), Viewports.for('xLargeUp'));
export const Title = styled.h2.withConfig({
  displayName: "TeaserElementstyles__Title",
  componentId: "sc-xdp3z7-1"
})(["margin:0;color:", ";", ";line-height:28px;", " ", "{", "}", "{", ";", "}"], Colors.GREY_d2d2d2, Fonts.bold(20), getMultilineTruncation(2, 26), Viewports.for('mediumUp'), getMultilineTruncation(2, 26), Viewports.for('xLargeUp'), Fonts.bold(34), getMultilineTruncation(2, 36));
export const Description = styled.span.withConfig({
  displayName: "TeaserElementstyles__Description",
  componentId: "sc-xdp3z7-2"
})(["", ";color:", ";", "{display:none;}", "{", ";}", "{", ";margin-top:6px;}"], Fonts.regular(16), Colors.GREY_d2d2d2, Viewports.for('small'), Viewports.for('mediumUp'), getMultilineTruncation(3, 26), Viewports.for('xLargeUp'), Fonts.regular(18));
export const Information = styled.div.withConfig({
  displayName: "TeaserElementstyles__Information",
  componentId: "sc-xdp3z7-3"
})(["display:grid;grid-template-rows:auto auto 1fr;grid-gap:16px;padding:16px 16px 24px;", "{padding:32px;grid-gap:12px;}", "{padding:40px 40px 0;max-width:770px;}", "{padding:48px 48px 0;max-width:770px;}@media only screen and (min-width:", "px) and (max-width:1400px){padding:28px 48px 0;}"], Viewports.for('mediumUp'), Viewports.for('largeUp'), Viewports.for('xLargeUp'), xLargeRange.from);
export const MetaSection = styled.div.withConfig({
  displayName: "TeaserElementstyles__MetaSection",
  componentId: "sc-xdp3z7-4"
})(["display:grid;grid-template-columns:auto minmax(0,1fr);grid-gap:16px;", "{grid-template-columns:100%;}"], Viewports.for('small'));
export const MediaInfo = styled.div.withConfig({
  displayName: "TeaserElementstyles__MediaInfo",
  componentId: "sc-xdp3z7-5"
})(["display:flex;align-items:center;color:", ";", ";", ";"], Colors.GREY_d2d2d2, Fonts.regular(16), ellipsis);
export const ShowName = styled.span.withConfig({
  displayName: "TeaserElementstyles__ShowName",
  componentId: "sc-xdp3z7-6"
})(["", ";"], ellipsis);