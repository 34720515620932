import { usePathByMediaType } from './use-path-by-media-type';
import { useValidTopic } from './use-valid-topic';
import { toSeoString } from '../../../play-core/utils/to-seo-string';
import { usePath } from '../config/VideoAppContext';
export const usePacPageLink = (section, medias) => {
  const {
    link,
    title: sectionTitle
  } = section.representation;
  const topic = useValidTopic([{
    urn: link?.target || ''
  }]);
  const {
    sectionDetailPath,
    microPagePath,
    videoShowPath,
    topicPath
  } = usePath();
  const firstMediaLink = usePathByMediaType(medias?.[0]);

  // TODO: as soon as PacPageLink enrichment is done in SAM, we should use the enriched titles
  const title = sectionTitle ? toSeoString(sectionTitle).toLowerCase() : '-';
  switch (link?.targetType) {
    case 'DETAIL_PAGE':
      return medias?.length === 1 ? firstMediaLink : sectionDetailPath(section);
    case 'MICRO_PAGE':
      return microPagePath({
        title,
        id: link.target
      });
    case 'SHOW_PAGE':
      return videoShowPath({
        title,
        id: link.target
      });
    case 'TOPIC_PAGE':
      return topic ? topicPath(topic.title) : undefined;
    default:
  }
};