import { createRequestUrl } from '../../../../play-core/utils/request-url-helper';
export const MostClickedShowsByTopicApiPath = '/most-clicked-shows-by-topic';
export const loadMostClickedShowsByTopic = async (axios, topicId) => {
  const requestUrl = createRequestUrl(MostClickedShowsByTopicApiPath, {
    topicId
  });
  const {
    data
  } = await axios.get(requestUrl);
  return data.data;
};