import { styled, css } from 'styled-components';
import { Colors, hexToRGB } from '../../../../play-core/styles/Colors';
import { Fonts } from '../../../../play-core/styles/Fonts';
import { headingStyle } from '../../../../play-core/styles/heading-style';
import { getMappedMediaQuery, getMultilineTruncation } from '../../../../play-core/styles/style-helper';
import { Viewports } from '../../../../play-core/styles/Viewports';
import { DensityAwareImage } from '../Image/DensityAwareImage';
import { PlayContainer, containerWidth } from '../PlayContainer/PlayContainer';
export const Layout = styled.div.withConfig({
  displayName: "Highlightstyles__Layout",
  componentId: "sc-1c8rt8x-0"
})(["position:relative;display:grid;grid-template-columns:100%;color:", ";background-color:", ";&:after{content:'';position:absolute;top:0;left:0;height:100%;width:100%;opacity:0;transition:opacity 200ms ease-in-out;pointer-events:none;}&:hover{&:after{opacity:1;background-image:linear-gradient(to bottom,", ",transparent 55%);}}"], Colors.WHITE, Colors.GREY_2a2a2a, hexToRGB(Colors.GREY_1a1a1a, 0.5));
export const ShadowOverlay = styled.div.withConfig({
  displayName: "Highlightstyles__ShadowOverlay",
  componentId: "sc-1c8rt8x-1"
})(["position:absolute;top:0;left:0;width:100%;height:100%;background-image:linear-gradient(200deg,", " 50%,", " 100%),linear-gradient(180deg,", " 50%,", " 100%);"], hexToRGB(Colors.GREY_1a1a1a, 0), hexToRGB(Colors.GREY_1a1a1a, 0.8), hexToRGB(Colors.GREY_1a1a1a, 0), hexToRGB(Colors.GREY_1a1a1a, 1));
export const highlightElementHeight = css(["height:200px;", "{height:280px;}", "{height:480px;}", "{height:480px;}"], Viewports.for('medium'), Viewports.for('large'), Viewports.for('xLargeUp'));
export const Image = styled(DensityAwareImage).withConfig({
  shouldForwardProp: () => true
}).withConfig({
  displayName: "Highlightstyles__Image",
  componentId: "sc-1c8rt8x-2"
})(["", ";@media only screen and (min-width:", "px){border-radius:2px;}"], highlightElementHeight, containerWidth.max);
export const StyledPlayContainer = styled(PlayContainer).withConfig({
  displayName: "Highlightstyles__StyledPlayContainer",
  componentId: "sc-1c8rt8x-3"
})(["position:absolute;z-index:2;", ""], getMappedMediaQuery({
  default: '24px',
  mediaQuery: [['medium', '32px'], ['large', '48px'], ['largeUp', '72px']]
}, 'bottom'));
export const Title = styled.div.withConfig({
  displayName: "Highlightstyles__Title",
  componentId: "sc-1c8rt8x-4"
})(["", ";margin-top:16px;", "{", ";}"], headingStyle, Viewports.for('small'), getMultilineTruncation(2, 28));
export const Description = styled.div.withConfig({
  displayName: "Highlightstyles__Description",
  componentId: "sc-1c8rt8x-5"
})(["padding-top:16px;", ";line-height:24px;", "{display:none;}", "{max-width:450px;}", "{max-width:550px;", ";line-height:26px;}", "{max-width:750px;}"], Fonts.light(16), Viewports.for('small'), Viewports.for('medium'), Viewports.for('largeUp'), Fonts.light(18), Viewports.for('xLargeUp'));