import { styled } from 'styled-components';
import { Colors, hexToRGB } from '../../../../play-core/styles/Colors';
import { Viewports } from '../../../../play-core/styles/Viewports';
export const Container = styled.div.withConfig({
  displayName: "HeroStageContentstyles__Container",
  componentId: "sc-1v8l4y2-0"
})(["display:none;position:relative;", "{display:block;}"], ({
  visibleViewport
}) => Viewports.for(visibleViewport));
export const Background = styled.div.withConfig({
  displayName: "HeroStageContentstyles__Background",
  componentId: "sc-1v8l4y2-1"
})(["position:absolute;top:0;left:0;right:0;z-index:-1;"]);
export const BackgroundGradient = styled.div.withConfig({
  displayName: "HeroStageContentstyles__BackgroundGradient",
  componentId: "sc-1v8l4y2-2"
})(["position:absolute;width:100%;height:100%;top:0;pointer-events:none;background-image:linear-gradient(200deg,", " 50%,", " 100%),linear-gradient(180deg,", " 50%,", " 100%);"], hexToRGB(Colors.GREY_1a1a1a, 0), hexToRGB(Colors.GREY_1a1a1a, 0.8), hexToRGB(Colors.GREY_1a1a1a, 0), hexToRGB(Colors.GREY_1a1a1a, 1));