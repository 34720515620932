import { createRequestUrl } from '../../../../play-core/utils/request-url-helper';
export const ShowSectionApiPath = '/show-section';
export const loadShowSection = async (axios, sectionId, preview, previewDate) => {
  const requestUrl = createRequestUrl(ShowSectionApiPath, {
    sectionId,
    preview,
    previewDate
  });
  const {
    data
  } = await axios.get(requestUrl);
  return data.data;
};